.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(-100px);
  transition: all 500ms ease-in-out;
  z-index: 9999;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(-100px);
}

.register {
  background-image: url('./images/hero-2new.jpg');
}

.customer-logos {
  display: flex;
}

.register {
  background-image: url(./images/hero-2new.jpg);
}

.rc-slider-track {
  background-color: #ff0000 !important;
}

.rc-slider-handle {
  border: solid 2px #ff0000 !important;
}

.settings-select {
  background: #f2f2f2;
  border-radius: 8px;
  padding: 8px;
  border: none;
}

.location-search-input {
  border-radius: 8px;
  padding: 6px 10px;
  border: none;
  background: #ff0000;
  color: #ffffff;
}

.suggestion-item {
  background: #f2f2f2;
  color: #212529;
}

.suggestion-item--active {
  background: #ff0000;
  color: #ffffff;
}

.register-lg-6 {
  margin-top: 5rem;
}







.lightbox {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  align-items: center;

  visibility: hidden;
  opacity: 0;

  transition: opacity ease 0.4s;
}

.show {
  visibility: visible;
  opacity: 1;
}

.show_image {
  width: 100vw;
  height: 100vh;
  object-fit: contain;
}








.myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  display: block;
  margin-left: auto;
  margin-right: auto;

}

.myImg:hover {opacity: 0.7;}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999999999; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}


    /* Modal Content (image) */
    .modal-content {
      margin: auto;
      display: block;
      width: 75%;
      //max-width: 75%;
  }

  #caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
}

#img01{
  z-index: 9999;
}

@-webkit-keyframes zoom {
  from {-webkit-transform:scale(1)}
  to {-webkit-transform:scale(2)}
}

@keyframes zoom {
  from {transform:scale(0.4)}
  to {transform:scale(1)}
}

@-webkit-keyframes zoom-out {
  from {transform:scale(1)}
  to {transform:scale(0)}
}
@keyframes zoom-out {
  from {transform:scale(1)}
  to {transform:scale(0)}
}

    /* Add Animation */
    .modal-content, #caption {
      -webkit-animation-name: zoom;
      -webkit-animation-duration: 0.6s;
      animation-name: zoom;
      animation-duration: 0.6s;
  }

  .out {
    animation-name: zoom-out;
    animation-duration: 0.6s;
  }