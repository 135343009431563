html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
q,
em,
img,
small,
strong,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend {
    border: 0;
    outline: 0;
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent
}

body {
    line-height: 1;

}

ol,
ul {
    list-style: none
}

:focus {
    outline: 0
}

input,
textarea {
    margin: 0;
    outline: 0;
}

textarea {
    overflow: auto;
    resize: none;
    width: 85%;
    height: 100px;
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
    display: block;
}

body,
span,
input,
select,
textarea,
p,
a,
b,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Montserrat', sans-serif;
}

@font-face {
    font-family: 'Berliana Monoline Free Regular';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/Berliana Monoline - Free.woff') format('woff'),
        url('./fonts/Berliana Monoline - Free.otf') format('otf');
}

@font-face {
    font-family: 'Edwardian Script ITC regular';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/edwardian-script.ttf') format('ttf'),
        url('./fonts/edwardian-script.woff') format('woff');
}


@font-face {
    font-family: 'Elisabetta';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/Elisabetta.otf') format('otf'),
        url('./fonts/Elisabetta.ttf') format('ttf');
}




@font-face {
    font-family: 'Georgia Regular';
    font-weight: normal;
    src: url('./fonts/Georgia.ttf') format('ttf'),
        url('./fonts/georgia.woff') format('woff');
}

@font-face {
    font-family: 'Georgia Bold';
    /*font-weight: bold;*/
    src: url('./fonts/georgiab.ttf') format('ttf'),
        url('./fonts/georgiab.woff') format('woff');
}


.fs-noto-sans {
    font-family: 'Noto Sans', sans-serif;
}

.fs-raleway {
    font-family: 'Raleway', sans-serif;
}

.fs-roboto {
    font-family: 'Roboto', sans-serif;
}

.fs-georgia-regular {
    font-family: 'Georgia Regular';
}

.fs-georgia-bold {
    font-family: 'Georgia Bold';
}

.fs-edward-script {
    font-family: 'Edwardian Script ITC regular';
}

.fs-shrinkhand {
    font-family: 'Shrikhand', cursive;
    font-weight: normal;
    letter-spacing: 3px;
}

p,
a,
li {
    line-height: 1.6;
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
}

*,
*:after,
*:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
    line-height: 1.4;
}

/*body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 0px;
    background-color: #F5F5F5;
}

body::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #ccc;
}
*/
a:hover {
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    color: inherit;
}

a:hover,
a:focus {
    text-decoration: none;
}

.row {
    margin: 0;
}

.container {
    max-width: 1200px;
    /*padding: 0;*/
}

a {
    color: #ff0000;
}

section,
footer {
    padding: 4rem 0;
}

h2,
.h2 {
    font-size: 40px
}

h3 {
    font-size: 36px
}

h4,
.h4 {
    font-size: 28px
}

h5,
.h5 {
    font-size: 20px
}

h6 {
    font-size: 16px;
}

.fs-11 {
    font-size: 11px !important;
}

.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px;
}

.fw-regular {
    font-weight: 400;
}

.fw-medium {
    font-weight: 500;
}

.fw-bold {
    font-weight: 700;
}

.fw-ebold {
    font-weight: 800;
}

.fw-black {
    font-weight: 900;
}

.lowercase {
    text-transform: lowercase;
}

.capitalzie {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}

.underline {
    text-decoration: underline;
}

.cursor {
    cursor: pointer;
}

.bgcolor-transparent {
    background-color: transparent;
}

.bgcolor-red {
    background-color: #ff0000;
}

.bgcolor-white {
    background-color: #ffffff;
}

.bgcolor-black {
    background-color: #000000;
}

.bgcolor-black-1 {
    /*background-color:#0e0e0e;*/
    background-color: #000000;
}

.bgcolor-fb {
    background-color: #4267b2;
}

.bgcolor-purple {
    background-color: #2f0961;
}

.bgcolor-nblue {
    background-color: #110521;
}

.bgcolor-lt-gray {
    background-color: #e9e9e9;
}

.color-lt-gray {
    color: #e9e9e9;
}

.color-dark-gray {
    color: #505050;
}

.color-gray {
    color: #b0b0b0;
}

.color-fb {
    color: #4267b2;
}

.color-black {
    color: #000000;
}

.color-white {
    color: #ffffff;
}

.color-red {
    color: #ff0000;
}

.color-purple {
    color: #6a1db7;
}

.color-lt-green {
    color: #339200;
}

.position-relative {
    position: relative;
}

.bg-img {
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.design {
    position: relative;
}

.design::before {
    content: " ";
    background: url(./images/vertical-line.svg);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 9;
    height: 100%;
    width: 30px;

}

/*=======================================*/
.button {
    text-align: center;
    font-size: 17px;
    background: #ff0000;
    color: #fff;
    padding: 12px 24px;
    cursor: pointer;
    border-radius: 4px;
    display: inline-block;
    border: 2px solid #ff0000;
}

.button.rounded {
    border-radius: 36px !important;
}

.button:focus {
    outline: none;
}

.button.red {
    background: #ff0000;
}

.button.green {
    background: #2ba845;
    border-color: #2ba845;
}

.button.orange {
    background: #ff7300;
    border-color: #ff7300;
}

.button.purple {
    background: #2f0961;
    border-color: #2f0961;
}

.button.purple:hover {
    background: #520dad;
    color: #fff;
}

.button.white {
    background: #fff;
    color: #000;
}

.button.ghost {
    background: transparent;
}

.button.red:hover {
    background: #da0707;
    color: #fff;
}

.button.ghost:hover {
    background: #ff0000;
    color: #fff;
}

/*=======================================*/
.bgcolor-gray {
    background-color: #F2F2F2;
}

.bgcolor-green {
    background-color: #60c3cc;
}

.color-blue {
    color: #3F92D2;
}

.color-twitter {
    color: #15C7CF;
}

.color-green {
    color: #55be75;
}

.color-black {
    color: #121212;
}

.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.pb-0 {
    padding-bottom: 0 !important;
}

/*=======================================*/
.p-3 {
    padding: 0.75rem;
}

.p-4 {
    padding: 1rem !important;
}

.p-8 {
    padding: 2rem !important;
}

.px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}

.py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
}

/*=======================================*/
.m-auto {
    margin: 0 auto;
}

.my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}

.my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
}

.mt-8 {
    margin-top: 2rem !important;
}

.mt-16 {
    margin-top: 4rem !important;
}

.mt-24 {
    margin-top: 6rem !important;
}

.mt-12 {
    margin-top: 3rem !important;
}

.mb-4 {
    margin-bottom: 1rem !important;
}

.mb-8 {
    margin-bottom: 2rem !important;
}

.mb-12 {
    margin-bottom: 3rem !important;
}

.mb-16 {
    margin-bottom: 4rem !important;
}

/*=======================================*/
.b-0 {
    border: 0;
}

.border-black {
    border: 3px solid #000000;
}

.border-white {
    border: 3px solid #ffffff;
}

.border-bottom-black {
    border-bottom: 3px solid #000000;
}

.border-bottom-red {
    border-bottom: 3px solid #ff0000;
}

.border-green {
    border: 3px solid #00C2CB;
}

.radius {
    border-radius: 10px;
}

.radius-md {
    border-radius: 1rem;
    ;
}

.radius-lg {
    border-radius: 1.5rem;
}

.radius-xl {
    border-radius: 2rem;
}

/*=======================================*/
/*header css*/
.navigation {
    display: flex;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: center;
}

.navigation li a {
    font-size: 16px;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    color: #fff;
}

.navigation li {
    margin: 0 16px;
}

li {
    display: inline-block;
}

.v-middle {
    vertical-align: middle;
}

.v-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.pb-16 {
    padding-bottom: 4rem;
}

.hidden {
    display: none;
}

#lgMenu {
    width: 40%;
    left: -100%;
    height: 100%;
    top: 0;
    bottom: 0;
    overflow: auto;
    background-color: #0e0e0e;
    box-shadow: 1px 2px 10px #e8e8e8;
    position: fixed;
    z-index: 11;
    opacity: 0;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    /*padding: 0 !important;*/
}

#lgMenu.enter {
    opacity: 1;
    left: 0;

}

.mobile-nav li {
    display: block;
}

.mobile-nav li:last-child a {
    display: inline-block;
}

.mobile-nav li a {
    font-size: 16px;
    margin: 8px 0;
    display: block;
    padding: 8px 0;
    font-weight: 500;
    color: #fff;
}

.column {
    padding: 0 2rem;
}

.box-card {
    box-shadow: 0 0 9px #cccccc52;
    border-radius: 16px;
    padding: 80px 16px;
    height: 100%;
}

.w-90 {
    max-width: 90%;
}

.w-70 {
    max-width: 70%;
}

.scrollable {
    height: 500px;
    overflow-y: auto;
}

.scrollable::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    background-color: #ffffff;
    border: 1px solid #333;

}

.scrollable::-webkit-scrollbar {
    width: 10px;
    background-color: pink;
    border-radius: 20px;
}

.scrollable::-webkit-scrollbar-thumb {
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #ff0000;
}

.position-sticky {
    position: sticky;
    top: 0;
    z-index: 999;
}

/*========================================================================*/
.hvr-up,
.hvr-down,
.hvr-right,
.hvr-left {
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgb(0 0 0 / 0%);
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    cursor: pointer;
}

.hvr-down:hover,
.hvr-down:focus,
.hvr-down:active {
    -webkit-transform: translateY(15px);
    -ms-transform: translateY(15%);
    transform: translateY(15x);
}

.hvr-up:hover,
.hvr-up:focus,
.hvr-up:active {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15%);
    transform: translateY(-15x);
}

.hvr-right:hover,
.hvr-right:focus,
.hvr-right:active {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15%);
    transform: translateX(15x);
}

/*========================================================================*/
.show {
    display: block;
}

@-webkit-keyframes rotating

/* Safari and Chrome */
    {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

.float-right {
    float: right;
}

.modal {
    overflow: auto !important;
}

.top-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top-navigation .navbar-btns ul {
    display: flex;
    align-items: center;
    gap: 10px;
}

.top-navigation .navbar-btns ul li:nth-child(2) a {
    font-weight: bold;
}

.top-navigation .navbar-btns span,
.top-navigation .navbar-btns a {
    color: #fff;
    font-size: 14px;
}

.top-navigation li:nth-of-type(1) a:hover,
.top-navigation li:nth-of-type(3) a:hover {
    color: #ff0000;
}

.button.md {
    padding: 8px 16px;
}

.brand img {
    display: block;
    width: 240px;
}

.fixed {
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
    right: 0;
}

.hero {
    background-image: url('./images/hero-1.jpg');
    padding-bottom: 0px;
}

.signup-btn {
    background: url('./images/btn-bg.png');
    font-size: 36px;
    font-family: 'Elisabetta';
}

/* Slider */
.slick-slide {
    margin: 0px 8px;
    border: 1px solid #e4e1e1;
    padding: 4px 16px;
    border-radius: 9px;
}

.slick-slide img {
    width: 100%;
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
}

.slick-track:before,
.slick-track:after {
    display: table;
    content: '';
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

/*==================================== Gallery Section =======================================*/
.gallery {
    background: url('./images/gallery-bg.jpg');
}

.gallery-content {
    display: flex;
    flex-wrap: wrap;
}

.gallery-content>div {
    flex: 1 1 33%;
    /*grow | shrink | basis */
    height: auto;
    padding: 8px;
}

.gallery-content>div img {
    display: block;
    border-radius: 4px;
    object-fit: cover;
    height: 100%;
}

/*==================================== Features Section =======================================*/
.features {
    background: url('./images/features-bg.jpg');
}

.blurb img {
    width: 80px;
}

.blurb p {
    padding: 0 2rem;
}

.histories {
    background: url('./images/histories-bg.jpg');
}

#histories-slider .carousel-indicators li {
    margin-right: 6px;
    margin-left: 6px;
}

#histories-slider .carousel-indicators {
    bottom: -60px;
}

#histories-slider .carousel-indicators li {

    width: 24px;
    height: 24px;
    border-radius: 100%;
    border: 2px solid red;
    background-color: transparent;
    cursor: pointer;
}

#histories-slider h4 {}

#histories-slider .carousel-indicators .active {
    border-color: #fff;
}

#histories-slider .slide-img img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

#histories-slider .slide-img {
    max-width: 400px;
    height: 250px;
    margin: 0 auto;
    border-radius: 4px;
    overflow: hidden;
}

#histories-slider .carousel-caption {
    position: static;
    max-width: 70%;
    margin: 0 auto;
}

.recommendation {
    background: url('./images/recommendation-bg.jpg');
}

.recommendation h3 {
    font-size: 55px;
    font-weight: 600;

}

/*.reviews{
    background: url('./images/reviews-bg.jpg');
}*/
.mulitcolor-bg {
    background-image: url('./images/multicolors1.jpg');
}

.testimonials-img {
    max-width: 100%;
}

.dp {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    overflow: hidden;
    border: 2px solid #fff;
}

.dp img {
    width: 100%;
    object-fit: cover;
}

.testimonials {
    position: relative;
}

.rating-box {
    display: flex;
    padding: 16px;
    background: red;
    width: 430px;
    border-radius: 8px;
    position: absolute;
}

.rating-box>div {
    margin: 8px;
}

.rating-box ul {
    margin-bottom: 8px;
}

.rating-box li i {
    color: #000;
    font-size: 20px;
}

.rating-box p {
    font-size: 14px;
}

.rating-box.first {
    right: 0;
    top: 0;
}

.rating-box.second {
    left: 0;
    bottom: 100px;

}

footer .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

footer .footer-content li {
    display: block;
    font-size: 12px;
    margin: 1rem 0;

}

footer h5 {
    color: #fff;
    margin-bottom: 1.5rem;
    font-family: 'Noto Sans', sans-serif;
}

footer li a,
footer li {
    color: #b0b0b0;
    ;
}

footer li a:hover {
    color: #ff0000;
}

footer img {
    width: 90px;
}

.social-media li {
    display: inline-block !important;
    margin-top: 0 !important;

}

.social-media li a {
    background-color: #4267b2;
    width: 28px;
    height: 28px;
    display: inline-block;
    text-align: center;
    border-radius: 100%;
    color: #fff;
    font-size: 15px;
    line-height: 2;
}

.social-media li:nth-of-type(2) a {
    background-color: #1DA1F2;
}

.social-media li:nth-of-type(3) a {
    background-color: #cb2027;
}

.social-media li:nth-of-type(4) a {
    background-color: #517fa4;
}

.toggle-btn {
    width: 24px;
    margin-left: 24px;
    vertical-align: middle;
}

.signup-form {
    border: 1px solid #ff0000;
    max-width: 390px;
    margin: 0 auto;
    display: none;
    max-height: 500px;
    overflow-y: auto;
}

.signup-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}

.signup-buttons a {
    font-size: 11px;
    padding: 8px 8px;
    margin: 0 4px;
    display: block;
    width: 100%;
}

.signup-buttons a i {
    font-size: 15px;
    margin-right: 4px;
    vertical-align: middle;
}

.signup-form label {
    font-size: 14px;
}

.button.fb {
    background-color: #4267b2;
    border-color: #4267b2;
}

.button.fb:hover {
    background-color: #33569e;
    color: #fff;
}

.button.google {
    background-color: #fff;
    border-color: #fff;
    color: #000;
}

.button.google:hover {}

.button.google i {
    color: #d93025;
}

.signup-form form label {
    color: #fff;
    text-align: left;
}

.signup-form form .row {
    margin: 8px -10px;
}

.signup-form .form-control {
    font-size: 11px;
    color: #ffffff;
    background-color: #000;
    border: 1px solid #6a1db7;
    padding: .375rem .5rem;
    height: 36px;
}

.signup-form .form-check-label,
.signup-form .form-check-label a {
    font-size: 12px;
}

.signup-form .form-control:focus {
    color: #ffffff;
    background-color: #000;
    border-color: #6a1db7;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.birthday .col-sm-3:nth-of-type(1),
.birthday .col-sm-3:nth-of-type(2) {
    padding-right: 0 !important;
}

.profile {
    background-color: #2f0961;
    min-height: 100vh;
}

.overlay {
    position: absolute;
    top: 60%;
    bottom: 0;
    left: 0;
    right: 0;
    transition: .5s ease;
    background: linear-gradient(0deg, #000000bf, transparent);
}

.profile .content>div {
    /*padding: 0;*/

}

.profile .content {
    border-radius: 8px;
    border: 2px solid #ff0000;
    background-color: #ffffff;
    overflow: hidden;
    box-shadow: 0px 0px 25px #000000a8;
}

.profile .top-detail {
    color: #101010;
    display: flex;
    align-items: center;
    padding: 15px 8px;
    position: relative;
    z-index: 2;
}

.profile .top-detail h5 {
    font-size: inherit;
    font-weight: 600;
    width: 15%;
}

.profile .top-detail h5:nth-child(2) {
    text-align: center;
    width: 70%;
}

.profile .top-detail label {
    width: 15%;
    text-align: right;
}

.profile .top-detail h5 a {
    color: inherit;
    font-size: inherit;
    line-height: 0;
}

.profile .bottom-detail {
    background: #fff;
    padding: 30px 15px;
}

.profile .bottom-detail .tags {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    font-size: 16px;
}

.profile .bottom-detail .tags h5 {
    font-size: inherit;
    /* width: 33.333333%; */
}

/* .profile .bottom-detail .tags h5:nth-child(2) {
    text-align: center;
}

.profile .bottom-detail .tags h5:last-child {
    text-align: right;
} */

.profile .bottom-detail .tags_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    font-size: 14px;
}

.profile .bottom-detail .tags_btn .button {
    color: #fff;
    font-size: inherit;
}

.top-picks h5 {
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
    color: #fff;
    font-weight: 600;

}

.top-picks img {}

.tags h5 {
    font-weight: 700;
}

.tags span {
    color: #000;
}

.profile-details .top-picks {
    padding: 8px;
}

.profile-details {
    padding: 0;
    background-color: #fff;
}

.bio-data {
    padding: 16px 24px;
    border-top: 4px solid #e9e9e9;
}

.bio-data ul li {
    margin-right: 24px;
}

.main-profile {
    object-fit: cover;
}

.main-overlay {
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3), transparent);
    left: 0;
    top: 0;
    width: 100%;
    height: 75px;
    z-index: 1;
}

.legal {
    background-image: url('./images/legal-bg.jpg');
}

.legal-content p {
    padding-right: 32px;
}

.contact-form .row {
    margin: -15px;
}

.contact-form input,
.contact-form textarea,
.contact-form input:focus,
.contact-form textarea:focus {
    border: 3px solid #000000;
    font-size: 15px;
    height: 48px;
    border-radius: 8px;
}

.contact-form textarea,
.contact-form textarea:focus {
    height: 160px;
}

.login {

    margin: 0 auto;
}

.filters .list {
    display: flex;
    justify-content: space-between;
    margin: 0 0 24px;
    align-items: center;
}

.filters .list p {
    width: 100%;
    text-align: left;
    max-width: 300px;
}

.switch-wrap {
    cursor: pointer;
    background: #c9c9c9;
    width: 46px;
    height: 22px;
    border-radius: 36px;
}

.switch-wrap input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.switch {
    height: 100%;
    display: grid;
    grid-template-columns: 0fr 1fr 1fr;
    transition: 0.2s;
}

.switch::after {
    content: '';
    border-radius: 100%;
    background: #ff0000;
    grid-column: 2;
    transition: background 0.2s;
    border: 2px solid #fff;
}

input:checked+.switch {
    grid-template-columns: 1fr 1fr 0fr;
}

input:checked+.switch::after {
    background-color: #52cf71;
}

.membersList {
    padding: 30px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.member-details .title p {
    font-weight: bold;
}

.title {
    display: flex;
    justify-content: space-between;
}

.member {
    display: flex;
    width: 100%;
    background: red;
    border-radius: 16px;
    overflow: hidden;
    max-width: 450px;
}

.member .member-dp {
    width: 25%;
}

.member .member-details {
    width: 75%;
    padding: 20px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

}

.member .member-dp img {
    width: 100%;
    height: 100%;
    max-height: 170px;
    object-fit: cover;
}

.member .member-details .title {
    display: block;
}

.member .member-details .title a {
    color: inherit;
    display: flex;
    justify-content: space-between;
}

.member .description p {
    overflow-y: auto;
}

.member .online {
    color: #00d739;
}

.chat h5 {
    padding: 12px 16px;
    text-align: center;
    color: #fff;
}

.uer-profile {
    width: 50px;
}

.uer-profile img {
    border-radius: 100%;
    overflow: hidden;
    width: 68px;
    height: 68px;
    object-fit: cover;
    border: 4px solid #f2f2f2;
}

.personal-details {
    width: calc(100% - 50px);
}

.chat-search input {
    background: #f2f2f2;
    border: 1px solid #dadada;
    padding: 10px 16px;
    border-radius: 12px;
    width: 82%;
    border-radius: 12px;
    float: left;
    margin-right: 3%;
}

.chat-search i {
    background: #ff0000;
    padding: 13px;
    border-radius: 12px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
}

.chat-status p {
    font-size: 12px;
    margin-top: 0;
    font-weight: 500;
}

.chat-status span {
    background: #76C00D;
    height: 19px;
    width: 20px;
    border-radius: 100%;
    text-align: center;
    color: #fff;
    font-weight: 700;
    line-height: 1.4;
    display: block;
    font-size: 14px;
}

.people-list li {
    padding: 10px;
    cursor: pointer;
    width: 100%;
    position: relative;
    border-bottom: 1px solid #ccc;
    display: flex;
    gap: 1em;

}

.people-list li p {
    color: #7B8793;
    font-size: 13px;
    margin-top: 5px;
}

.people-list li h6 {
    font-size: 14px;
    font-weight: bold;
    color: #4B5155;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.people-list li p.chat-time {
    margin-top: 0;
    white-space: pre;
}

.people-list li.active {
    background: #ff0000;
}

.people-list li.active h6 a,
.people-list li.active p,
.people-list li.active .chat-time {
    color: #ffffff;
}

.chat-time {
    font-size: 14px;
    font-weight: 600;
}

.pref-suf-profile {
    border-radius: 100%;
    overflow: hidden;
    width: 68px;
    height: 68px;
    line-height: 3;
    text-align: center;
    background: #E2EDDB;
    font-size: 24px;
    color: #759A40;
}

.chating-person-info .person-dp {
    /*max-width: 150px;*/
    /*height: 200px;*/
    /*margin:0 auto;*/
}

.chating-person-info .user-actions a {
    font-size: 24px;
    margin: 0 16px;
}

.person-dp img {
    width: 120px;
    height: 160px;
    object-fit: cover;
}

.chat-files .col-lg-4 {
    padding: 0 3px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.people-list {
    height: 605px;
    overflow-y: auto;
    margin-bottom: 10px;
}

.chat-list {
    height: 600px;
    overflow-y: auto;
}

.scrollable-grey::-webkit-scrollbar-track {
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
    border-radius: 10px;
    background-color: #F5F5F5;
}

.scrollable-grey::-webkit-scrollbar {
    width: 8px;
    background-color: #F5F5F5;
}

.scrollable-grey::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
    background-color: #DBE5ED;
}

.chat-history {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 20px;
}

.messages li {
    display: flex;
    align-items: flex-start;
    gap: 15px;
}

.messages li+li {
    margin-top: 20px;
}

.messages li .msg-img {
    max-width: 160px;
}

.msg-bubble p {
    font-size: 14px;
    padding: 5px 16px;
    border-radius: 10px;
    margin: 0;
    display: inline-block;

}

.receiver .msg-bubble p {
    background: #ff0000;
    color: #ffffff;
}

.bubble-time {
    font-weight: 600;
    font-size: 11px;
    color: #7B8793;
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 5px;
    flex-direction: row-reverse;
}

.msg-bubble {
    width: 70%;
}

.msg-img img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    overflow: hidden;
    object-fit: cover;
}

.messages li.sender {
    flex-direction: row-reverse;
}

.sender .msg-bubble p {
    background: #f2f2f2;
    /* text-align: -webkit-right;
    text-align: right;*/
}

.sender .msg-bubble {
    text-align: -webkit-right;
}

.chat-input {

    background-repeat: no-repeat;
    background-position: 12% 50%;
    width: 100%;
    border-radius: 12px;
    border: 1px solid #DBE5ED;
    padding: 12px 56px 12px 20px;
    font-size: 14px;
    font-style: italic;
    font-style: italic;
}

.additonal-options {
    position: absolute;
    left: 6px;
    top: 8px;
    cursor: pointer;
}

.extra-features {
    position: absolute;
    left: 0;
    top: -200px;
    background-color: #ff0000;
    padding: 8px;
    display: none;
}

.extra-features.active {
    display: block;
}

.extra-features li {
    display: block;
    text-align: center;
    margin: 8px 0 2px;
}

.msg-status {
    background: #df1010;
    padding: 5px;
    border-radius: 100%;
    width: 26px;
    height: 26px;
    display: inline-block;
    text-align: center;
    color: #fff;
    font-weight: 600;
    line-height: 1.2;
    position: absolute;
    left: -8px;
}

.people-list li.active .msg-status,
.people-list li.active h6 i {
    display: none;
}

.extra-features li i {
    color: #fff;
    border: 0;
    padding: 0;
    font-size: 20px;
}

.additonal-options i {
    font-size: 29px;
    border-right: 1px solid #ccc;
    color: #ff0000;
    padding-right: 10px;
}

.click-options {
    position: absolute;
    right: 7px;
    top: 6px;
}

.click-options li {
    margin-left: 10px;
    cursor: pointer;
    vertical-align: middle;
}

.click-options li img {
    margin-top: 5px;
}

.send-btn {
    border: 0;
    background: transparent;
    cursor: pointer;
}

.send-btn i {
    font-size: 30px;
    color: #ff0000;

}

.chat-screen {
    padding: 24px;
}

.image-upload input {
    opacity: 0;
    width: 23px;
    background: red;
    position: absolute;
    left: 45px;
}

.togglebox {
    display: none;
}

.pricing {
    max-width: 1100px;
    margin: 0 auto;
}

.price-details {
    background-color: #fff;
    padding: 64px 32px;
    border-radius: 24px;
}

.price-details li {
    display: block;
    margin: 16px 0;
    font-weight: 600;
    font-size: 15px;
}

.price-details li i {
    margin-right: 12px;
}

.xleft-img {
    position: absolute;
    right: -30px;
    top: -24px;
    width: 80px;
}

.c-img {
    position: absolute;
    left: -50px;
    bottom: -70px;
    width: 140px;
}

.xright-img {
    position: absolute;
    left: -20px;
    top: 260px;
    width: 80px;
}

.m-img {
    position: absolute;
    left: -50px;
    bottom: 100px;
    width: 120px;
}

.rectangle-img {
    position: absolute;
    right: -40px;
    top: -50px;
    width: 110px;
}

.circle-img {
    position: absolute;
    right: -36px;
    bottom: -36px;
    width: 100px;
}

.badge-img {
    position: absolute;
    right: 90px;
    top: 0;
    width: 64px;
}

.styled-checkbox {
    position: absolute;
    opacity: 0;
}

.styled-checkbox+label {
    position: relative;
    cursor: pointer;
    padding: 0;
}

.styled-checkbox+label:before {
    content: '';
    margin-right: 24px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: #dddddd;
}

.styled-checkbox:hover+label:before {
    background: #2ba845;
}

.styled-checkbox:focus+label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.styled-checkbox:checked+label:before {
    background: #2ba845;
}

.styled-checkbox:disabled+label {
    color: #b8b8b8;
    cursor: auto;
}

.styled-checkbox:disabled+label:before {
    box-shadow: none;
    background: #ddd;
}

.styled-checkbox:checked+label:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 11px;
    background: white;
    width: 3px;
    height: 3px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
}

.unstyled {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.setting-images {
    padding: 32px 120px;
}

.works img {
    max-width: 120px;
}

.podium {
    max-width: 100%;
}

/*==================================== Media Queries =======================================*/
@media screen and (min-width: 1920px) {
    .full-width-container {
        max-width: 1200px;
        margin: auto;
    }
}

@media screen and (max-width: 1199px) {
    .navigation li a {
        font-size: 14px;
    }

    .navigation li {
        margin: 0px 10px;
    }

    .top-navigation li:nth-of-type(2) {
        margin: 0 0;
    }
}

@media screen and (max-width: 991px) {
    .md-hidden {
        display: none;
    }

    .md\:show {
        display: block;
    }

    .md\:py-12 {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .md\:mt-8 {
        margin-top: 2rem;
    }

    .md\:mt-16 {
        margin-top: 4rem;
    }

    .v-center,
    .v-center.detail {
        position: inherit;
        left: 50%;
        top: 50%;
        right: inherit;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    h2,
    .h2 {
        font-size: 2rem;
    }

    h3,
    .h3 {
        font-size: 24px;
    }

    h4,
    .h4 {
        font-size: 20px;
    }

    h5,
    .h5 {
        font-size: 16px;
    }

    .recommendation h3 {
        font-size: 45px;
        font-weight: 600;
        line-height: 1;
    }

    .navbar-btns ul {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    /*.testimonials-img {
        width: 80%;
    }*/
    section,
    footer {
        padding: 3rem 0;
    }

    .signup-image {
        max-width: 90% !important;
    }

    footer .footer-content li {
        margin: 12px 0;
    }

    footer li a,
    footer li {
        font-size: 14px;
    }

    .mobile-nav .navbar-btns {
        display: none;
    }

    .membersList {
        padding: 32px;
    }

    .member {
        height: 146px;
    }

    .member .description p {
        height: 48px;
    }
}

@media screen and (max-width: 767px) {
    .sm-order {
        display: flex;
        flex-direction: row;
    }

    .sm-order>div:nth-of-type(1) {
        order: 2;
    }

    .sm-order>div:nth-of-type(2) {
        order: 3;
    }

    .sm-order>div:nth-of-type(3) {
        order: 1;
    }

    .sm\:hidden {
        display: none;
    }

    .sm\:my-4 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    .sm\:my-8 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }

    .sm\:text-center {
        text-align: center;
    }

    .sm\:m-auto {
        margin: auto !important;
    }

    .sm\:mt-0 {
        margin-top: 0 !important;
    }

    .sm\:mt-8 {
        margin-top: 2rem !important;

    }

    .sm\:mt-16 {
        margin-top: 4rem !important;

    }

    .sm\:py-12 {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .sm\:py-16 {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    .sm\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .sm\:mt-8 {
        margin-top: 2rem !important;
    }

    .sm\:p-4 {
        padding: 1rem !important;
    }

    .sm\:mb-4 {
        margin-bottom: 1rem !important;
    }

    .sm\:mb-0 {
        margin-bottom: 0rem !important;
    }

    .sm\:mb-8 {
        margin-bottom: 2rem !important;
    }

    h2,
    .h2 {
        font-size: 1.5rem;
    }

    h4,
    .h4 {
        font-size: 20px;
    }

    .blurb p {
        padding: 0;
    }

    p,
    li {
        font-size: 14px;
    }

    .navbar-btns ul li:not(:last-child) {
        display: none;
    }

    .brand img {
        width: 150px;
    }

    .rating-box {
        padding: 12px;
        width: 100%;
        position: static;
        margin: 16px auto;
        flex-direction: column;
        align-items: center;
    }

    /*.testimonials-img {
        width: 90%;
    }*/
    .rating-box>div {
        margin: 8px;
        text-align: center !important;
    }

    .button {
        font-size: 16px;
        padding: 8px 16px;
    }

    .gallery-content>div {
        flex: 1 1 48%;
    }

    section,
    footer {
        padding: 2rem 0;
    }

    .hero .row .right {
        order: 2;
    }

    .hero .row .center {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        order: 3;
    }

    .hero .row .left,
    .hero .row .right {
        padding: 0;
    }

    footer .footer-content {
        flex-direction: column;
    }

    footer .footer-content ul {
        margin-bottom: 2rem;
    }

    footer .footer-content li {
        margin: 4px 0;
    }

    footer h5 {
        margin-bottom: 1rem;
    }

    .ribbon-image {
        max-width: 50%;
    }

    .recommendation h3 {
        font-size: 36px;
        font-weight: normal;
    }

    .mobile-nav .navbar-btns {
        display: block;
    }

    .mobile-nav .navbar-btns ul li,
    .mobile-nav .navbar-btns a {
        display: inline-block;

    }

    .mobile-nav .navbar-btns a {
        font-size: 14px;
        padding: 4px 8px;
    }

    .mobile-nav .navbar-btns ul li:last-child a {
        margin-left: 8px;
    }

    #lgMenu {
        width: 100%;
    }

    .signup-buttons {
        flex-direction: column;
    }

    .signup-form {
        max-width: 100%;
    }

    .signup-buttons a {
        margin: 4px 0;
    }

    .birthday .col-sm-3:nth-of-type(1),
    .birthday .col-sm-3:nth-of-type(2) {
        padding-right: 15px !important;
    }

    .birthday .col-sm-3:nth-of-type(2) {
        margin: 8px 0;
    }

    .profile .bottom-detail {
        bottom: 16px;
    }

    .legal-content p {
        padding-right: 16px;
    }

    .login {
        max-width: 100%;
    }

    .design::before {
        width: 15px;
    }

    .rectangle-img,
    .circle-img {
        right: 0;
    }

    /*.podium{
        max-width: 90%;
    }*/


}

@media screen and (max-width: 576px) {
    .top-picks .col-lg-3 {
        width: 50%;
    }

    .setting-images {
        padding: 32px;
    }
}

@media screen and (max-width: 480px) {
    .gallery-content>div {
        flex: 1 1 100%;
        padding: 4px 0;
    }

    #histories-slider .slide-img {
        max-width: 100%;
        height: 250px;

    }

    #histories-slider .carousel-caption {
        max-width: 100%;
    }

    .or-image {
        width: 100%;
    }

    .member .member-dp {
        width: 35%;
    }

    .member .member-details {
        width: 65%;
    }

    .membersList {
        padding: 32px 0;
    }
}

@media screen and (max-width: 375px) {
    #histories-slider .slide-img {
        max-width: 100%;
        height: 200px;
    }

    .ornament-image {
        width: 100%;
    }
}

.legal-content .list-number {
    /*padding-left: 30px;*/
    list-style: decimal;
    list-style-position: inside;
}

.legal-content .list-number li {
    display: revert;
    text-transform: capitalize;
}

.legal-content ul li,
.legal-content p {
    font-size: 1.05em;
    line-height: 1.8;
}

.legal-content h4 {
    text-transform: capitalize;
}

.MuiMobileStepper-root button {
    cursor: pointer;
}

.MuiMobileStepper-root button span {
    max-width: 60px;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
}

/* ---------------------------------------------
            03-11-2022
--------------------------------------------- */
.gap-3 {
    gap: 3em 0;
}

.gap-2 {
    gap: 2em 0;
}

/* ---------------------------------------------
            20-01-2023
--------------------------------------------- */
.text-pink {
    color: #df34fa !important;
}

.table td,
.table th {
    padding: 5px 15px;
}

/* ---------------------------------------------
            18-02-2023
--------------------------------------------- */
.lighty {
    display: block;
    cursor: pointer;
}

.lighty img {
    width: 100%;
}

/* ---------------------------------------------
            20-3-2023
--------------------------------------------- */
.gap-y-1 {
    gap: 1em 0;
}
.shrinkhand-link {
    letter-spacing: normal;
}
.shrinkhand-link b {
    font-family: 'Shrikhand', cursive;
    font-weight: normal;
}